import { createRoute, lazyRouteComponent, redirect } from "@tanstack/react-router"
import { initTsrReactQuery } from "@ts-rest/react-query/v5"
import { z } from "zod"
import config from "../config"
import { contract } from "../features/performance-services/contracts"
import { getPersistedAuth } from "../utils/auth"
import { authenticatedRoute } from "./authenticated-route"

export const performanceServiceDash = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => authenticatedRoute,
	path: "water_billing_usage",
	beforeLoad: async ( context ) => {
		const tsr = initTsrReactQuery( contract, {
			baseUrl: config.psi_api,
			baseHeaders: {
				Authorization: `Token ${getPersistedAuth()}`,
			},
			validateResponse: true,
		} )
		const pathChunks = context.location.pathname.split( "/" ).filter( Boolean )

		const isIndex = pathChunks.length === 1

		const psiQueryClient = tsr.initQueryClient( context.context.queryClient )
		const data = await psiQueryClient.invoices.listProjects.ensureQueryData( {
			queryKey: [ "projects" ],
		} )
		const projects = data.body.results
		const hasProjects = data.body.results.length

		if ( isIndex ) {
			if ( hasProjects ) {
				throw redirect( {
					to: listInvoicesRoute.id,
					search: { page: 1 },
					params: {
						projectId: projects[ 0 ].id.toString(),
					},
				} )
			}
		}

		return {
			title: hasProjects ? projects[ 0 ].project_name : "",
			isIndex,
			tsr,
			psiQueryClient,
			hasProjects,
		}
	},
} )

export const projectsRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => performanceServiceDash,
	path: "$projectId",
	beforeLoad: async ( { params, context } ) => {
		const { psiQueryClient } = context
		const { projectId } = params

		const pathChunks = location.pathname.split( "/" ).filter( Boolean )
		if ( pathChunks.length === 2 ) {
			throw redirect( {
				to: listInvoicesRoute.id,
				search: { page: 1 },
				params: {
					projectId: projectId,
				},
			} )
		}

		const projectDetails = await psiQueryClient.invoices.listProjects.ensureQueryData( {
			queryKey: [ "projects" ],
		} )

		const currentProject = projectDetails.body.results.find(
			( project ) => project.id.toString() === projectId,
		)

		return {
			title: currentProject ? currentProject.project_name : "",
			projectDetails: currentProject,
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/Layout" ),
		"Layout",
	),
} )

export const listInvoicesRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => projectsRoute,
	path: "invoices",
	beforeLoad: ( { location } ) => {
		const pathChunks = location.pathname.split( "/" ).filter( Boolean )
		const isIndex = pathChunks.length === 3
		return {
			title: "Invoice Extraction",
			isIndex,
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/invoices/ListInvoices" ),
		"ListInvoices",
	),
	validateSearch: z.object( {
		page: z.number().int().min( 1 ).optional(),
		project: z.number().optional(),
	} ),
} )

export const listDraftInvoicesRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => projectsRoute,
	path: "draft-invoices",
	beforeLoad: ( { location } ) => {
		const pathChunks = location.pathname.split( "/" ).filter( Boolean )

		const isIndex = pathChunks.length === 3
		return {
			title: "Draft Invoices",
			isIndex,
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/invoices/ListDraftInvoices" ),
		"ListDraftInvoices",
	),
	validateSearch: z.object( {
		page: z.number().int().min( 1 ).optional(),
	} ),
} )

export const createInvoiceRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => listInvoicesRoute,
	path: "create_invoice/",
	beforeLoad: () => {
		return {
			title: "Add New Invoice",
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/invoices/CreateInvoice" ),
		"CreateInvoice",
	),
} )

export const invoiceSettingsRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => listInvoicesRoute,
	path: "$id/invoice_settings",
	params: {
		parse: ( params ) => z.object( { id: z.coerce.number() } ).parse( params ),
		stringify: ( params ) => z.object( { id: z.coerce.string() } ).parse( params ),
	},
	beforeLoad: () => {
		return {
			title: "Extracted Data Preview",
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/invoices/InvoiceSettings" ),
		"InvoiceSettings",
	),
} )

export const validationQueueRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => projectsRoute,
	path: "validation_queue",
	beforeLoad: ( { location } ) => {
		const pathChunks = location.pathname.split( "/" ).filter( Boolean )

		const isIndex = pathChunks.length === 3
		return {
			title: "Validation Queue",
			isIndex,
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/validation/ValidationQueue" ),
		"ValidationQueue",
	),
	validateSearch: z.object( {
		page: z.number().int().min( 1 ).optional(),
	} ),
} )
export const invoicesRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => projectsRoute,
	path: "invoices",
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/invoices/ListInvoices" ),
		"ListInvoices",
	),
} )

export const reviewDataRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => validationQueueRoute,
	path: "$id/review_data",
	params: {
		parse: ( params ) => z.object( { id: z.coerce.number() } ).parse( params ),
		stringify: ( params ) => z.object( { id: z.coerce.string() } ).parse( params ),
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/validation/ReviewData/ReviewData" ),
		"ReviewData",
	),
} )

export const masterDataRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => projectsRoute,
	path: "master_data",
	validateSearch: z.object( {
		warning_flag: z.boolean().optional(),
	} ),
	beforeLoad: ( { location, context } ) => {
		const pathChunks = location.pathname.split( "/" ).filter( Boolean )

		const isIndex = pathChunks.length === 3
		const mergeChannel = context.pusher.subscribe( `psi-notif-${context.me.id}` )
		return {
			title: "Master Data",
			isIndex,
			mergeChannel,
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/master-data/MasterData" ),
		"MasterData",
	),
} )

export const executiveReportRoute = createRoute( {
	wrapInSuspense: true,
	getParentRoute: () => projectsRoute,
	path: "executive_report",
	beforeLoad: ( { location } ) => {
		const pathChunks = location.pathname.split( "/" ).filter( Boolean )

		const isIndex = pathChunks.length === 3
		return {
			title: "Executive Report",
			isIndex,
		}
	},
	component: lazyRouteComponent(
		() => import( "@/features/performance-services/ui/master-data/ExecutiveReport" ),
		"ExecutiveReport",
	),
} )
