import { cn } from "@/utils/cn"
import { cva, styled } from "@/utils/cva"
import { Cross2Icon } from "@radix-ui/react-icons"
import * as ToastPrimitives from "@radix-ui/react-toast"
import * as React from "react"

const ToastProvider = ToastPrimitives.Provider

const ToastViewport = styled(
	ToastPrimitives.Viewport,
	cva(
		"fixed top-0 z-[100] flex max-h-screen w-full flex-col-reverse gap-2 p-4 sm:bottom-0 sm:right-0 sm:top-auto sm:flex-col md:max-w-[320px]",
	),
)
ToastViewport.displayName = ToastPrimitives.Viewport.displayName

const toastVariants = cva(
	"group pointer-events-auto relative flex w-full items-center justify-between space-x-4 overflow-hidden rounded-3 border p-4 pr-8 shadow-lg backdrop-blur-md transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full",
	{
		variants: {
			variant: {
				default: "border border-gray-7 bg-gray-2 text-gray-11",
				destructive: "border border-crimson-7 bg-crimson-2 text-crimson-11",
				success: "border border-grass-7 bg-grass-2 text-grass-11",
				warning: "border border-amber-7 bg-amber-2 text-amber-11",
				info: "border border-sky-7 bg-sky-2 text-sky-11",
			},
		},
		defaultVariants: {
			variant: "default",
		},
	},
)

const Toast = styled( ToastPrimitives.Root, toastVariants )
Toast.displayName = ToastPrimitives.Root.displayName

const ToastAction = styled(
	ToastPrimitives.Action,
	cva(
		"hover:bg-secondary focus:ring-ring group-[.destructive]:border-muted/40 group-[.destructive]:hover:border-destructive/30 group-[.destructive]:hover:bg-destructive group-[.destructive]:hover:text-destructive-foreground group-[.destructive]:focus:ring-destructive inline-flex h-8 shrink-0 items-center justify-center rounded-2 border bg-transparent px-3 text-sm font-medium ring-offset-background transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 disabled:pointer-events-none disabled:opacity-50",
	),
)
ToastAction.displayName = ToastPrimitives.Action.displayName

const ToastClose = React.forwardRef<
	React.ElementRef<typeof ToastPrimitives.Close>,
	React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>( ( { className, ...props }, ref ) => (
	<ToastPrimitives.Close
		ref={ref}
		className={cn(
			"absolute right-2 top-2 rounded-1 p-1 text-gray-11 opacity-0 transition-opacity hover:text-gray-12 focus:opacity-100 focus:outline-none focus:ring-2 group-hover:opacity-100",
			className,
		)}
		{...props}
	>
		<Cross2Icon className="h-4 w-4" />
	</ToastPrimitives.Close>
) )

ToastPrimitives.ToastClose.displayName = ToastPrimitives.Close.displayName

const ToastTitle = styled( ToastPrimitives.Title, cva( "break-all text-sm font-medium" ) )
ToastTitle.displayName = ToastPrimitives.Title.displayName

const ToastDescription = styled( ToastPrimitives.Description, cva( "text-sm opacity-90" ) )
ToastDescription.displayName = ToastPrimitives.Description.displayName

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>
type ToastActionElement = React.ReactElement<typeof ToastAction>

export {
	Toast,
	ToastAction,
	ToastClose,
	ToastDescription,
	ToastProvider,
	ToastTitle,
	ToastViewport,
	type ToastActionElement,
	type ToastProps,
}
