import { z } from "zod"
import { c } from "./contract-instance"

export const MergedInvoiceDataSchema = z.object( {
	id: z.number(),
	account_number: z.string().nullable(),
	meter_number: z.string().nullable(),
	customer_name: z.string().nullable(),
	street_address: z.string().nullable(),
	city_state: z.string().nullable(),
	size_code: z.string().nullable(),
	meter_size: z.string().nullable(),
	status: z.string().nullable(),
	billing_period_start_date: z.string().nullable(),
	billing_period_end_date: z.string().nullable(),
	consumption: z.string().nullable(),
	rate_code: z.string().nullable(),
	meter_make: z.string().nullable(),
	meter_model: z.string().nullable(),
	installation_date: z.string().nullable(),
	water_charge: z.string().nullable(),
	sewer_charge: z.string().nullable(),
	total_charges: z.string().nullable(),
	utility_type: z.string().nullable(),
	days_of_service: z.string().nullable(),
	location: z.string().nullable(),
	warning_flag: z.boolean().nullable(),
	project_name: z.string().nullable(),
	project_id: z.number().nullable(),
	customer: z.string().nullable(),
	billing_software: z.string().nullable(),
	warning_details: z.string().nullable(),
	is_deleted: z.boolean().nullable(),
	created_at: z.string().nullable(),
	updated_at: z.string().nullable(),
	linked_invoices: z.array( z.any() ).nullable(),
} )

export const ListMasterDataOkResponse = z.object( {
	count: z.number(),
	next: z.string().nullable(),
	previous: z.string().nullable(),
	results: MergedInvoiceDataSchema.array(),
} )

export const ExecutiveReportRow = z.record( z.string(), z.union( [ z.number(), z.string(), z.null() ] ) )

export const ListExecutiveReportOkResponse = z.object( {
	detail: ExecutiveReportRow.array(),
} )

export const DownloadReportResponse = z.object( {
	file_url: z.string(),
} )

export const UpdateMasterDataRequestSchema = z.object( {
	customer_name: z.string().nullable().optional(),
	street_address: z.string().nullable().optional(),
	city_state: z.string().nullable().optional(),
	size_code: z.string().nullable().optional(),
	meter_size: z.string().nullable().optional(),
	status: z.string().nullable().optional(),
	billing_period_start_date: z.string().nullable().optional(),
	billing_period_end_date: z.string().nullable().optional(),
	consumption: z.string().nullable().optional(),
	rate_code: z.string().nullable().optional(),
	meter_make: z.string().nullable().optional(),
	meter_model: z.string().nullable().optional(),
	installation_date: z.string().nullable().optional(),
	water_charge: z.string().nullable().optional(),
	sewer_charge: z.string().nullable().optional(),
	total_charges: z.string().nullable().optional(),
	utility_type: z.string().nullable().optional(),
	days_of_service: z.string().nullable().optional(),
	location: z.string().nullable().optional(),
	warning_flag: z.boolean().nullable(),
} )

export const masterData = c.router(
	{
		listMasterData: {
			method: "GET",
			path: "/:project_id",
			query: z.object( {
				page: z.number().min( 1 ).optional().default( 1 ),
				warning_flag: z.boolean().optional(),
			} ),
			responses: {
				200: ListMasterDataOkResponse,
			},
		},
		listExecutiveReport: {
			method: "GET",
			path: "/report/:project",
			pathParams: z.object( {
				project: z.number(),
			} ),
			responses: {
				200: ListExecutiveReportOkResponse,
			},
		},
		downloadExecutiveReport: {
			method: "GET",
			path: "/executive-report/download/:project",
			pathParams: z.object( { project: z.number() } ),
			responses: {
				200: DownloadReportResponse,
			},
		},
		downloadMasterDataReport: {
			method: "GET",
			path: "/download/:project_id",
			pathParams: z.object( { project_id: z.number() } ),
			responses: {
				200: DownloadReportResponse,
			},
		},
		syncMasterDataToKnowledgeGraph: {
			method: "GET",
			path: "/sync",
			responses: {
				200: z.object( { details: z.string() } ),
			},
		},
		updateMasterData: {
			method: "PATCH",
			path: "/update/:invoice_id/",
			pathParams: z.object( { invoice_id: z.number() } ),
			body: UpdateMasterDataRequestSchema,
			responses: {
				200: UpdateMasterDataRequestSchema,
				400: z.object( {
					error: z.boolean(),
					details: z.object( {
						message: z.string(),
					} ),
				} ),
			},
		},
	},
	{ pathPrefix: "/master_data" },
)
